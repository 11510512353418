<template>
    <div class="cool-event">
        <div class="btns">
            <i class="x-icon-remind">
                <count-to :endVal="endVal" :startVal="startEnd" class="count"></count-to>
            </i>
            <i @click="_changeVoice" :class="isVoiceReady ? 'x-icon-sound' : 'x-icon-nsound'"></i>
            <i @click="_handleResume" class="x-icon-play"></i>
            <i @click="_handlePausey" class="x-icon-zanting"></i>
            <i @click="_handleCancel" class="x-icon-stop"></i>
            <i @click="_handleRemoveAll" class="x-icon-shanchu"></i>
        </div>
        <div class="event-content">
            <div v-for="eve in eves" :key="eve.key" :class="[eve.clazz, eve.play ? 'active' : '']" @click="_addMessage(eve)">
                <i :class="[eve.play && isVoiceReady ? 'x-icon-sound' : '']"></i>{{eve.message}}
            </div>
        </div>
    </div>
</template>
<script>
    import {X_COOL_MODEL_BACKGROUND_COLOR} from '@/components/x/cool/xcools';
    import countTo from 'vue-count-to';
    import {isNotEmpty} from "@/util/objects";
    import {v4} from 'uuid';

    const X_MSG_TYPE = {
        info: {
            clazz: "info",
            icon: "el-icon-chat-square",
        },
        success: {
            clazz: "success",
            icon: "el-icon-chat-dot-square",
        },
        warning: {
            clazz: "warning",
            icon: "el-icon-chat-dot-round",
        },
        danger: {
            clazz: "danger",
            icon: "el-icon-chat-line-round",
        }
    }

    export default {
        name: "XCoolEvent",
        components: {countTo},
        data() {
            this.isAddEve = false;
            return {
                startEnd: 0,
                endVal: 0,
                isVoiceReady: false,
                eves: []
            }
        },
        props: {
            max: {
                type: Number,
                default: 99
            }
        },
        watch: {
            "eves.length"(n, o) {
                this.startEnd = o;
                this.endVal = n;
            }
        },
        mounted() {
            document.addEventListener('click', this._initVoice);
        },
        beforeDestroy(){
            speechSynthesis.cancel();
        },
        methods: {
            _initVoice() {
                document.removeEventListener('click', this._initVoice);
                this.$emit("voiceReady");
                this._changeVoice();
            },
            _changeVoice() {
                this.isVoiceReady = !this.isVoiceReady;
                let u = new SpeechSynthesisUtterance();
                if (this.isVoiceReady) {
                    u.text = "语音播报已开启";
                    speechSynthesis.speak(u);
                } else {
                    u.text = "语音播报已关闭";
                    this._handleCancel();
                    speechSynthesis.speak(u);
                }
            },
            _handleResume() {
                speechSynthesis.resume();
            },
            _handlePausey() {
                speechSynthesis.pause();
            },
            _handleCancel() {
                speechSynthesis.cancel();
            },
            _handleRemoveAll() {
                this.eves = [];
                this._handleCancel();
            },
            _createSpeechSynthesisUtterance(bit, eve) {
                let u = new SpeechSynthesisUtterance();
                u.text = bit.message;
                u.lang = "zh";
                u.onstart = () => {
                    eve ? eve.play = true : bit.play = true;
                    if (bit.onStart && typeof bit.onStart === 'function') {
                        bit.onStart();
                    }
                }
                u.onend = () => {
                    eve ? eve.play = false : bit.play = false;
                    if (bit.onEnd && typeof bit.onEnd === 'function') {
                        bit.onEnd();
                    }
                }
                return u;
            },
            _addMessage(eve) {
                if (!this.isVoiceReady) return;
                if (eve.isBits) {// 一条消息多个 事件触发点
                    isNotEmpty(eve.events) && eve.events.forEach(e => {
                        let u = this._createSpeechSynthesisUtterance(e, eve);
                        speechSynthesis.speak(u);
                    })
                } else {
                    let u = this._createSpeechSynthesisUtterance(eve);
                    speechSynthesis.speak(u);
                }
            },
            _getEventByStr(msg) {
                return {
                    message: msg,
                    play: false,
                    key: v4(),
                    clazz: X_MSG_TYPE.success.clazz,
                    icon: X_MSG_TYPE.success.icon,
                };
            },
            _getEventByObject(eve) {
                let type = (eve.type && X_MSG_TYPE[eve.type]) || X_MSG_TYPE.success;
                return {
                    message: eve.msg,
                    play: false,
                    key: v4(),
                    clazz: type.clazz,
                    icon: type.icon,
                    onStart: eve.onStart,
                    onEnd: eve.onEnd
                }
            },
            _getEventByArray(eves) {
                let type = (eves.type && X_MSG_TYPE[eves.type]) || X_MSG_TYPE.success;
                let events = [];
                let msg = "";
                eves.events.forEach(e => {
                    msg += e.msg
                    events.push({
                        message: e.msg,
                        onStart: e.onStart,
                        onEnd: e.onEnd
                    })
                })
                return {
                    isBits: true,
                    events: events,
                    message: msg,
                    play: false,
                    key: v4(),
                    clazz: type.clazz,
                    icon: type.icon
                }
            },
            add(eve) {
                if (this.isAddEve) {
                    setTimeout(() => {
                        this.add(eve);
                    }, 100);
                    return false;
                }
                this.isAddEve = true;

                eve = eve || "新事件";

                let eventObject;
                //根据参数类型封装事件
                if (typeof eve === 'string') {
                    eventObject = this._getEventByStr(eve);
                } else if (eve.events) {
                    eventObject = this._getEventByArray(eve);
                } else {
                    eventObject = this._getEventByObject(eve);
                }
                this._addMessage(eventObject);
                //验证事件长度
                if (this.eves.length === this.max) {
                    this.eves.shift()//删除最早的事件
                }
                this.eves.push(eventObject);
                this.isAddEve = false;
            }
        }
    }
</script>