<template>
    <div class="cool-bg home">
        <div class="cool-border">
            <!-- 头部 -->
            <div class="cool-head">
                <span class="cool-head-title">{{$bjggs.APP_NAME}}</span>
            </div>
            <x-cool-selecter v-model="currentPage" :datas="pages"
                             noneLabel="切换子系统" width="200px" color="lan"
                             style="top: 12px;right: 150px;position: fixed" @change="changeSystem"/>
            <div style="display: flex;height: 90%">
                <div class="router-div">
                    <router-link  class="left-router" to="/ckjg">
                        <div class="x-guide-router">库存监管</div>
                    </router-link>
                    <router-link class="left-router" to="/dsxs">
                        <div class="x-guide-router">电商销售</div>
                    </router-link>
                    <router-link  class="left-router" to="/ddzz">
                        <div class="x-guide-router">订单跟踪</div>
                    </router-link>
                    <router-link class="left-router" to="/zljgKsh">
                        <div class="x-guide-router">质量溯源</div>
                    </router-link>
                    <router-link class="left-router" to="/spjk">
                        <div class="x-guide-router">视频监控</div>
                    </router-link>
                </div>
                <div class="router-div">
                    <router-link class="right-router" to="/san-wei-ke-shi-hua">
                        <div class="x-guide-router">粮情综合监管</div>
                    </router-link>
                    <router-link class="right-router"  to="/clddKsh">
                        <div class="x-guide-router">车辆调度</div>
                    </router-link>
                    <router-link class="right-router"  to="/qybz">
                        <div class="x-guide-router">区域分析</div>
                    </router-link>
                    <router-link class="right-router" to="/xjbz">
                        <div class="x-guide-router">行军保障分析</div>
                    </router-link>
                    <router-link class="right-router" to="/yjbzksh">
                        <div class="x-guide-router">应急保障</div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {deepCopy} from "../util/objects";
    import XCool from '@/components/x/cool/XCool';
    import XCoolSelecter from '@/components/x/cool/XCoolSelecter';

    export default {
        name: 'Guide',
        components: {XCool, XCoolSelecter},
        data() {
            return {
                currentPage: '/ksh.html',
                pages: deepCopy(this.$store.getters.subsystemRoutes),
            }
        },
        mounted() {
            this.pages.push({key: '/', label: '导航页'});
        },
        methods: {
            changeSystem(val) {
                if (val) {
                    window.location.href = val;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .right-router{
        margin-left: calc(100% - 423px);width: 300px
    }
    .left-router{
        margin-left: 140px;width: 300px
    }
    .router-div{
        display: flex;flex-direction: column;width: 50%;height: 100%;justify-content: space-around
    }
</style>
