<template>
    <div class="x-cool-selecter" :class="color" :style="{width:width}" @mouseleave="show = false">
        <div class="x-cool-selecter-text" @click="show = true">
            <div class="text">{{label}}</div>
            <div class="el-icon-arrow-down"></div>
        </div>
        <div class="x-cool-selecter-box" :style="{'display': (show ? 'block' : 'none')}">
            <div v-for="item in datas" :key="item.key"
                 :class="model === item.key ? 'selected' : ''" @click="selected(item.key)">{{item.label || item.title || item.name}}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'XBoxChang',
        data() {
            return {
                show: false
            }
        },
        computed: {
            model: {
                get() {
                    return this.value;
                },
                set(newVal) {
                    this.$emit("input", newVal);
                }
            },
            label() {
                let d = this.datas.find(o => o.key === this.model);
                return (d && (d.label || d.title || d.name)) || this.noneLabel;
            }
        },
        props: {
            width: {
                type: String,
                default: '120px'
            },
            datas: {
                type: Array,
                required: true
            },
            value: [String, Number],
            color: {
                type: String,
                default: 'bai' // bai  lan 两种
            },
            noneLabel : {
                type: String,
                default: '请选择'
            }
        },
        methods: {
            selected(key) {
                this.model = key;
                this.show = false;
                this.$emit('selected', key);
                this.$emit('change', key);
            }
        }
    }
</script>

<style lang="less" scoped>
</style>
