<template>
    <div class="cool-bg" :class="coolBG">
        <!-- 头部 -->
        <div class="cool-head" :class="headBG">
            <div class="time">{{currentTime}}</div>
            <span class="cool-head-title" @click="showExit ? $router.go(-1):null" style="cursor: pointer">{{title}}</span>
            <x-cool-event ref="eve" :max="eventMax" @voiceReady="voiceReady" v-if="showEvent"></x-cool-event>
            <div class="exit x-icon-tuichu" @click="$router.go(-1)" v-if="showExit">退出</div>
        </div>
        <!-- 核心 -->
        <div class="cool-centre">
            <slot name="centre"></slot>
        </div>
        <slot></slot>
    </div>
</template>
<script>
    import "./xcool.scss";
    import XCoolEvent from "@/components/x/cool/XCoolEvent";
    import {getDateNow, X_COOL_BACKGROUND, X_COOL_HEAD_BG} from "@/components/x/cool/xcools";

    export default {
        name: "XCool",
        components: {XCoolEvent},
        data() {
            return {
                currentTime: "正在读取系统时间..."
            }
        },
        mounted() {
            this.$parent.eve = this.$refs.eve;
            this.currentTimeInterval = setInterval(() => {
                this.currentTime = getDateNow();
            }, 1000)
        },
        beforeDestroy() {
            clearInterval(this.currentTimeInterval);
        },
        props: {
            coolBG: {
                type: String,
                default: X_COOL_BACKGROUND.default
            },
            headBG: {
                type: String,
                default: X_COOL_HEAD_BG.sky
            },
            title: {
                type: String,
                default: "可视化平台"
            },
            showEvent: {
                type: Boolean,
                default: false
            },
            showExit: {
                type: Boolean,
                default: false
            },
            eventMax: {
                type: Number,
                default: 99
            }
        },
        methods: {
            voiceReady(){
                this.$emit("voiceReady");
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>
