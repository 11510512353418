import {v4} from 'uuid';
import {isNotEmpty} from "@/util/objects";

export const X_COOL_MODEL_BACKGROUND_COLOR = {
    default: "rgba(0, 6, 23, 0.4)",
    white: "rgba(255, 255, 255, 0.5)",
    red: "rgba(255, 0, 0, 0.5)",
    green: "rgba(0, 255, 0, 0.5)",
    blue: "rgba(0, 0, 255, 0.5)",
    orange: "rgba(255, 122, 0, 0.5)",
    yellow: "rgba(255, 255, 0, 0.5)",
    purple: "rgba(122, 0, 255, 0.5)",
    pink: "rgba(255, 0, 255, 0.5)"
}

export const X_COOL_HEAD_BG = {
    default: "sky",
    sky: "sky",
    starry: "starry"
}

export const X_COOL_BACKGROUND = {
    default: "sky",
    sky: "sky",
    home:"home",
    starry: "starry"
}

export function getDateNow() {
    let date = new Date();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let h = date.getHours();
    let mm = date.getMinutes();
    let s = date.getSeconds();
    m < 10 && (m = '0' + m);
    d < 10 && (d = '0' + d);
    h < 10 && (h = '0' + h);
    mm < 10 && (mm = '0' + mm);
    s < 10 && (s = '0' + s);
    return `${date.getFullYear()}-${m}-${d} ${h}:${mm}:${s}`;
}

export const X_MSG_TYPE = {
    info: {
        clazz: "info",
        icon: "el-icon-chat-square",
    },
    success: {
        clazz: "success",
        icon: "el-icon-chat-dot-square",
    },
    warning: {
        clazz: "warning",
        icon: "el-icon-chat-dot-round",
    },
    danger: {
        clazz: "danger",
        icon: "el-icon-chat-line-round",
    }
}

export const Event = function (msg) {
    this.message = msg;
    this.play = false;
    this.key = v4();
    this.clazz = X_MSG_TYPE.success.clazz;
    this.icon = X_MSG_TYPE.success.icon;
}
Event.prototype.setType = function (type) {
    this.clazz = type.clazz;
    this.icon = type.icon;
    return this;
}
Event.prototype.info = function () {
    return this.setType(X_MSG_TYPE.info);
}
Event.prototype.success = function () {
    return this.setType(X_MSG_TYPE.success);
}
Event.prototype.warning = function () {
    return this.setType(X_MSG_TYPE.warning);
}
Event.prototype.danger = function () {
    return this.setType(X_MSG_TYPE.danger);
}
Event.prototype.bits = function (events) {
    this.events = events;
    this.isBits = true;
    let message = "";
    if (isNotEmpty(events)) {
        events.forEach(o => {
            message += o.message;
        })
    }
    this.message = message;
    return this;
}


